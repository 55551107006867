<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo">
						<h3>Restaurant</h3>
						<h4 class="small">Template</h4>
					</div>
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.restaurantName }}</h4>
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>
					</p>
				</div>
				<div class="columns column3">
					<h4>{{ $t('contact') }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<p>
						<a
							href="https://www.becurious.com/"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by becurious"
						>
							Website Design
						</a>
						<span>by:</span>
						<a href="https://www.becurious.com" target="_blank">
							<img src="~/assets/images/logo-becurious-white.png" loading="lazy" alt="Becurious logo" />
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.footers {
	color: #fff;
	background: var(--footer-background-color);

	a,
	span {
		color: var(--footer-color);
		text-decoration: none;
	}

	h3,
	h4 {
		color: var(--footer-color);
		letter-spacing: 1px;
	}

	h4 {
		font-family: var(--body-font-family);
		font-size: 1.3em;
	}
}

.main-footer {
	padding: 75px 0;
	font-size: 12px;

	p {
		font-size: 0.9em;
	}

	a,
	span {
		display: block;
	}

	a:hover {
		text-decoration: underline;
	}

	.footer-logo {
		max-width: 180px;
		margin: 0 0 30px;
	}
}

.author-footer {
	font-size: 0.8em;
	padding: 5px 0;
	text-align: center;
	width: 100%;

	p {
		margin: 15px 0;
	}

	.border {
		background: var(--secondary-background-color);
		width: 100%;
		height: 1px;
	}

	img {
		max-width: 90px;
		margin: -1px 1px 3px 2px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 0 auto 20px;
	}
}

@media (max-width: 640px) {
	.main-footer {
		padding: 30px 5px;
	}
}
</style>
