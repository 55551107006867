<template>
	<a @click.prevent="openBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
// import BookingEngine from '@becurious/';
// let bookingengine = {};

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

const openBookingEngine = (options) => {
	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	// window.open(bookingengine.getBookingUrl(options));
};

onMounted(() => {
	// bookingengine = new BookingEngine({});
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
